import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';

const AnswerForm = ({ correctAnswer, onSubmit, difficulty }) => {
  const [userInput, setUserInput] = useState('');
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const trimmedInput = userInput.trim();
      setUserInput(trimmedInput);

      // Event tracking: User submits an answer
      mixpanel.track("Answer Submitted", {difficulty: difficulty, question: correctAnswer, userInput: trimmedInput});

      if (trimmedInput === correctAnswer) {
        setIsAnswerCorrect(true);
        setUserInput('');

        // Event tracking: Correct answer submitted
        mixpanel.track("Correct Answer", {difficulty: difficulty, question: correctAnswer});

        onSubmit(); // Call the provided onSubmit function
      } else {
        setIsAnswerCorrect(false);

        // Event tracking: Incorrect answer submitted
        mixpanel.track("Incorrect Answer", {difficulty: difficulty, question: correctAnswer});
      }
    }
  };

  const handleChange = (e) => {
    setUserInput(e.target.value);
    if (isAnswerCorrect !== null) setIsAnswerCorrect(null);
  };

  return (
    <div className="input-container">
      <div className={`answer-form ${isAnswerCorrect === false ? 'incorrect-answer' : ''}`}>
        <input
          id="answer-text"
          type="text"
          placeholder="Answer..."
          value={userInput}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="key">
        Click <span id="enter">Enter</span> to submit an answer
      </div>
    </div>
  );
};

export default AnswerForm;