import React, { useState, useEffect } from 'react';
import './App.css';
import AnswerForm from './AnswerForm';
import { FaArrowRight, FaArrowLeft, FaBreadSlice } from 'react-icons/fa6';
import { IconContext } from 'react-icons/lib';
import { createClient } from "@supabase/supabase-js";
import googlelogo from "./img/glogo.png";
import discordlogo from "./img/discord.png";
import tippy, {createSingleton} from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import mixpanel from 'mixpanel-browser';
import brain from "./icons/brain.svg";
 
mixpanel.init('460d0134fa295cf1132bef54bd3a53be', {debug: true, track_pageview: true, persistence: 'localStorage'});
 
// Set this to a unique identifier for the user performing the event.
mixpanel.identify('USER_ID')


const supabase = createClient(
  "https://umxfmxbhdqkrqpjamoiv.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVteGZteGJoZHFrcnFwamFtb2l2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDM5MDY5ODksImV4cCI6MjAxOTQ4Mjk4OX0.BE120XTrl8klJarBOM7qBlxLL7YqmT2-iZg6ueD3oRA",
);


const shuffleArray = (array) => {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const App = () => {
  const [difficulties, setDifficulties] = useState({
    Padawan: { selected: true },
    Apprentice: { selected: false },
    Jedi: { selected: false },
  });
  const [currentProblemIndex, setCurrentProblemIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [currentProblems, setCurrentProblems] = useState(null);

  const problems = {
    Padawan: shuffleArray([
      { question: '87 + 54', answer: '141' },
      { question: '165 - 78', answer: '87' },
      { question: '23 + 68', answer: '91' },
      { question: '124 - 37', answer: '87' },
      { question: '49 + 86', answer: '135' },
      { question: '103 - 46', answer: '57' },
      { question: '61 + 72', answer: '133' },
      { question: '148 - 39', answer: '109' },
      { question: '34 + 127', answer: '161' },
      { question: '192 - 67', answer: '125' },
      { question: '84 + 103', answer: '187' },
      { question: '127 - 64', answer: '63' },
      { question: '58 + 129', answer: '187' },
      { question: '112 - 19', answer: '93' },
      { question: '77 + 59', answer: '136' },
      { question: '135 - 47', answer: '88' },
      { question: '29 + 134', answer: '163' },
      { question: '199 - 58', answer: '141' },
      { question: '78 + 42', answer: '120' },
      { question: '57 + 68', answer: '125' },
      { question: '150 - 67', answer: '83' },
      { question: '39 + 124', answer: '163' },
      { question: '87 - 28', answer: '59' },
      { question: '134 + 68', answer: '202' },
      { question: '105 - 39', answer: '66' },
      { question: '27 + 93', answer: '120' },
      { question: '191 - 64', answer: '127' },
      { question: '68 + 99', answer: '167' },
      { question: '119 - 48', answer: '71' },
      { question: '38 + 129', answer: '167' },
      { question: '147 - 58', answer: '89' },
      { question: '72 + 89', answer: '161' },
      { question: '136 - 49', answer: '87' },
      { question: '19 + 134', answer: '153' },
      { question: '190 - 47', answer: '143' },
      { question: '55 + 128', answer: '183' },
      { question: '110 - 29', answer: '81' },
      { question: '43 + 124', answer: '167' },
      { question: '178 - 69', answer: '109' },
      { question: '76 + 92', answer: '168' },
      { question: '122 - 39', answer: '83' },
      { question: '34 + 119', answer: '153' },
      { question: '196 - 78', answer: '118' },
      { question: '94 + 108', answer: '202' },
      { question: '179 - 56', answer: '123' },
      { question: '47 + 134', answer: '181' },
      { question: '192 - 75', answer: '117' },
      { question: '83 + 76', answer: '159' },
      { question: '147 - 38', answer: '109' },
      { question: '56 + 128', answer: '184' },
      { question: '182 - 57', answer: '125' },
      { question: '67 + 89', answer: '156' },
      { question: '120 - 43', answer: '77' },
      { question: '59 + 98', answer: '157' },
      { question: '176 - 39', answer: '137' },
      { question: '43 + 109', answer: '152' },
      { question: '198 - 79', answer: '119' },
      { question: '91 + 59', answer: '150' },
      { question: '135 - 48', answer: '87' },
      { question: '27 + 84', answer: '111' },
      { question: '163 - 68', answer: '95' },
      { question: '68 + 59', answer: '127' },
      { question: '104 - 37', answer: '67' },
      { question: '53 + 118', answer: '171' },
      { question: '143 - 58', answer: '85' },
      { question: '29 + 109', answer: '138' },
      { question: '121 - 38', answer: '83' },
      { question: '62 + 119', answer: '181' },
      { question: '169 - 38', answer: '131' },
      { question: '56 + 99', answer: '155' },
      { question: '187 - 69', answer: '118' },
      { question: '81 + 79', answer: '160' },
      { question: '116 - 39', answer: '77' },
      { question: '64 + 83', answer: '147' },
      { question: '182 - 57', answer: '125' },
      { question: '38 + 68', answer: '106' },
      { question: '119 - 48', answer: '71' },
      { question: '44 + 134', answer: '178' },
      { question: '165 - 78', answer: '87' },
      { question: '79 + 54', answer: '133' },
      { question: '144 - 29', answer: '115' },
      { question: '48 + 114', answer: '162' },
      { question: '123 - 38', answer: '85' },
      { question: '97 + 108', answer: '205' },
      { question: '149 - 48', answer: '101' },
      { question: '28 + 89', answer: '117' },
      { question: '121 - 34', answer: '87' },
      { question: '55 + 84', answer: '139' },
      { question: '198 - 59', answer: '139' },
      { question: '77 + 84', answer: '161' },
      { question: '134 - 49', answer: '85' },
      { question: '73 + 119', answer: '192' },
      { question: '172 - 39', answer: '133' },
      { question: '87 + 104', answer: '191' },
      { question: '145 - 58', answer: '87' },
      { question: '58 + 94', answer: '152' },
      { question: '190 - 67', answer: '123' },
      { question: '67 + 109', answer: '176' },
      { question: '156 - 29', answer: '127' },
      { question: '78 + 89', answer: '167' },
      { question: '113 - 46', answer: '67' },
      { question: '64 + 83', answer: '147' },
      { question: '178 - 59', answer: '119' },
      { question: '86 + 104', answer: '190' },
      { question: '133 - 38', answer: '95' },
      { question: '47 + 128', answer: '175' },
      { question: '168 - 39', answer: '129' },
      { question: '69 + 93', answer: '162' },
      { question: '188 - 67', answer: '121' },
      { question: '72 + 99', answer: '171' },
      { question: '142 - 69', answer: '73' },
      { question: '29 + 98', answer: '127' },
      { question: '180 - 67', answer: '113' },
      { question: '95 + 118', answer: '213' },
      { question: '137 - 58', answer: '79' },
      { question: '78 + 99', answer: '177' },
      { question: '186 - 57', answer: '129' },
      { question: '67 + 104', answer: '171' },
      { question: '128 - 39', answer: '89' },
      { question: '83 + 99', answer: '182' },
      { question: '166 - 38', answer: '128' },
      { question: '43 + 128', answer: '171' },
      { question: '192 - 47', answer: '145' },
      { question: '54 + 79', answer: '133' },
      { question: '128 - 59', answer: '69' },
      { question: '67 + 129', answer: '196' },
      { question: '175 - 68', answer: '107' },
      { question: '46 + 89', answer: '135' },
      { question: '143 - 58', answer: '85' },
      { question: '87 + 54', answer: '141' },
      { question: '165 - 78', answer: '87' },
      { question: '23 + 68', answer: '91' },
      { question: '124 - 37', answer: '87' },
      { question: '49 + 86', answer: '135' },
      { question: '103 - 46', answer: '57' },
      { question: '61 + 72', answer: '133' },
      { question: '148 - 39', answer: '109' },
      { question: '34 + 127', answer: '161' },
      { question: '192 - 67', answer: '125' },
      { question: '84 + 103', answer: '187' },
      { question: '127 - 64', answer: '63' },
      { question: '58 + 129', answer: '187' },
      { question: '112 - 19', answer: '93' },
      { question: '77 + 59', answer: '136' },
      { question: '135 - 47', answer: '88' },
      { question: '29 + 134', answer: '163' },
      { question: '199 - 58', answer: '141' },
      { question: '78 + 42', answer: '120' },
      { question: '57 + 68', answer: '125' },
      { question: '150 - 67', answer: '83' },
      { question: '39 + 124', answer: '163' },
      { question: '87 - 28', answer: '59' },
      { question: '134 + 68', answer: '202' },
      { question: '105 - 39', answer: '66' },
      { question: '27 + 93', answer: '120' },
      { question: '191 - 64', answer: '127' },
      { question: '68 + 99', answer: '167' },
      { question: '119 - 48', answer: '71' },
      { question: '38 + 129', answer: '167' },
      { question: '147 - 58', answer: '89' },
      { question: '72 + 89', answer: '161' },
      { question: '136 - 49', answer: '87' },
      { question: '19 + 134', answer: '153' },
      { question: '190 - 47', answer: '143' },
      { question: '55 + 128', answer: '183' },
      { question: '110 - 29', answer: '81' },
      { question: '43 + 124', answer: '167' },
      { question: '178 - 69', answer: '109' },
      { question: '76 + 92', answer: '168' },
      { question: '122 - 39', answer: '83' },
      { question: '34 + 119', answer: '153' },
      { question: '196 - 78', answer: '118' },
      { question: '94 + 108', answer: '202' },
      { question: '179 - 56', answer: '123' },
      { question: '47 + 134', answer: '181' },
      { question: '192 - 75', answer: '117' },
      { question: '83 + 76', answer: '159' },
      { question: '147 - 38', answer: '109' },
      { question: '56 + 128', answer: '184' },
      { question: '182 - 57', answer: '125' },
      { question: '67 + 89', answer: '156' },
      { question: '120 - 43', answer: '77' },
      { question: '59 + 98', answer: '157' },
      { question: '176 - 39', answer: '137' },
      { question: '43 + 109', answer: '152' },
      { question: '198 - 79', answer: '119' },
      { question: '91 + 59', answer: '150' },
      { question: '135 - 48', answer: '87' },
      { question: '27 + 84', answer: '111' },
      // Add more easy problems
    ]),
    Apprentice: shuffleArray([
      { question: '7 + 3 × 12', answer: '43' },
      { question: '18 ÷ (3 + 3)', answer: '3' },
      { question: '15 - 2²', answer: '11' },
      { question: '9 × 4 - 7', answer: '29' },
      { question: '(5 + 3) × 6', answer: '48' },
      { question: '14 - 3 × 5', answer: '-1' },
      { question: '10 + 2 × 15', answer: '40' },
      { question: '25 ÷ (2 + 3)', answer: '5' },
      { question: '11 + 3²', answer: '20' },
      { question: '17 × 2 - 8', answer: '26' },
      { question: '14 - 2 × 7', answer: '0' },
      { question: '6 + 4 × 8', answer: '38' },
      { question: '12 + 2 × 14', answer: '40' },
      { question: '16 ÷ (4 + 2)', answer: '4' },
      { question: '9 + 3 × 11', answer: '42' },
      { question: '13 - 2 × 5', answer: '3' },
      { question: '18 ÷ (2 + 3)', answer: '3.6' },
      { question: '7 + 3 × 14', answer: '49' },
      { question: '16 - 2²', answer: '12' },
      { question: '11 × 4 - 6', answer: '38' },
      { question: '22 + 8 ÷ 2', answer: '26' },
      { question: '(6 + 3) × 7', answer: '63' },
      { question: '17 - 4 × 2', answer: '9' },
      { question: '14 + 3 × 16', answer: '62' },
      { question: '24 ÷ (2 + 4)', answer: '4' },
      { question: '13 + 3²', answer: '22' },
      { question: '18 × 3 - 9', answer: '45' },
      { question: '27 ÷ (4 + 1)', answer: '5.4' },
      { question: '15 - 2 × 8', answer: '-1' },
      { question: '8 + 5 × 9', answer: '53' },
      { question: '20 ÷ (3 + 2)', answer: '4' },
      { question: '10 + 2 × 17', answer: '44' },
      { question: '14 ÷ (4 + 3)', answer: '2' },
      { question: '9 + 3 × 12', answer: '45' },
      { question: '13 - 3 × 5', answer: '-2' },
      { question: '18 ÷ (2 + 4)', answer: '3' },
      { question: '7 + 4 × 13', answer: '59' },
      { question: '16 - 3²', answer: '7' },
      { question: '11 × 5 - 7', answer: '48' },
      { question: '22 + 9 ÷ 3', answer: '25' },
      { question: '(6 + 3) × 8', answer: '72' },
      { question: '17 - 5 × 2', answer: '7' },
      { question: '14 + 4 × 15', answer: '74' },
      { question: '13 + 4²', answer: '29' },
      { question: '18 × 4 - 8', answer: '64' },
      { question: '24 ÷ (4 + 2)', answer: '4' },
      { question: '15 - 3 × 9', answer: '-12' },
      { question: '8 + 6 × 9', answer: '62' },
      { question: '20 ÷ (3 + 2)', answer: '4' },
      { question: '10 + 3 × 17', answer: '61' },
      { question: '9 + 4 × 13', answer: '61' },
      { question: '13 - 4 × 5', answer: '-7' },
      { question: '7 + 5 × 14', answer: '77' },
      { question: '12.5 ÷ (3 + 2)', answer: '2.5' },
      { question: '16 - 4²', answer: '0' },
      { question: '11 × 6 - 7', answer: '59' },
      { question: '22 + 10 ÷ 2', answer: '27' },
      { question: '(6 + 4) × 7', answer: '70' },
      { question: '17 - 6 × 2', answer: '5' },
      { question: '14 + 5 × 15', answer: '89' },
      { question: '23 ÷ (2 + 6)', answer: '2' },
      { question: '13 + 5²', answer: '38' },
      { question: '18 × 5 - 9', answer: '81' },
      { question: '27 ÷ (4 + 3)', answer: '3' },
      { question: '15 - 4 × 8', answer: '-17' },
      { question: '8 + 7 × 9', answer: '71' },
      { question: '20 ÷ (3 + 2)', answer: '4' },
      { question: '10 + 4 × 17', answer: '78' },
      // Add more medium problems
    ]),
    Jedi: shuffleArray([
      { question: "3x + 5 = 2x - 1", answer: "-6" },
      { question: "2x - 7 = 5 - x", answer: "4" },

      // Add more hard problems
    ]),
  };

  const difficultyInstructions = {
    Padawan: "Find the answer",
    Apprentice: "Find the answer",
    Jedi: "Solve for X",
  };

  const getSelectedDifficulty = (difficulties) => {
    return Object.keys(difficulties).find((difficulty) => difficulties[difficulty].selected);
  };

  const currentDifficulty = getSelectedDifficulty(difficulties);
  const currentProblem = currentProblems ? currentProblems[currentProblemIndex] : null;

  const updateCurrentProblems = () => {
    setCurrentProblems(shuffleArray(problems[currentDifficulty]));
  };



  const getNextProblem = () => {
    setShowPopup(false);

    setCurrentProblemIndex((prevIndex) => prevIndex + 1);
  };

  const [consecutiveCorrect, setConsecutiveCorrect] = useState(0);

  const getNextProblemSubmit = () => {
    setShowPopup(true);
    setCurrentProblemIndex((prevIndex) => prevIndex + 1);
    setConsecutiveCorrect((prevCount) => {
      const newCount = prevCount + 1;
      onSubmit(newCount);
      return newCount;
    });
  };

  const getPreviousProblem = () => {
    setCurrentProblemIndex((prevIndex) => Math.max(0, prevIndex - 1));
    setShowPopup(false);
  };

  const handleDifficultyChange = (selectedDifficulty) => {
    setDifficulties((prevDifficulties) => {
      const updatedDifficulties = { ...prevDifficulties };
      Object.keys(updatedDifficulties).forEach((difficulty) => {
        updatedDifficulties[difficulty].selected = difficulty === selectedDifficulty;
      });
      return updatedDifficulties;
    });
    setCurrentProblemIndex(0);
    setShowPopup(false);
    updateCurrentProblems();
  };

  useEffect(() => {
    updateCurrentProblems();
  }, [currentDifficulty]);

  const ProblemComponent = ({ question }) => {
    return <h2>{question}</h2>;
  };


  
  const Popup = ({ isCorrect }) => {
    return (
      <div className={`popup ${isCorrect ? 'correct' : ''}`}>
        <p>Correct!</p>
        <div className="green-line"></div>
      </div>
    );
  };

  useEffect(() => {
    // Use useEffect to manage showing/hiding the popup
    if (showPopup) {
      const popupTimer = setTimeout(() => {
        setShowPopup(false);
      }, 3000); // Hide the popup after 3 seconds

      return () => {
        clearTimeout(popupTimer);
      };
    }
  }, [showPopup]);


  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  


  const handleAnswer = (userInput, prevCount) => {
    const trimmedInput = userInput.trim();
  
    if (trimmedInput === currentProblem.answer) {
      setIsAnswerCorrect(true);
      setShowPopup(true);
      

    } else {
      setIsAnswerCorrect(false);
      setShowPopup(false);
    }
  
  };

  const [elo, setElo] = useState(0);
  

  const difficultyEloPoints = {
    Padawan: [1, 2, 3],
    Apprentice: [3, 4, 5],
    Jedi: [5, 6, 7],
  };
  
  const calculateEloPoints = () => {
    const difficultyPoints = difficultyEloPoints[currentDifficulty];
    const randomIndex = Math.floor(Math.random() * difficultyPoints.length);
    return difficultyPoints[randomIndex];
  };

  
  const fixElo = () => {
    const pointsEarned = calculateEloPoints();
    setElo((prevElo) => prevElo + pointsEarned);
    console.log("fix elo:", elo);
  };

  const [session, setSession] = useState(null);


  // Function to handle login
  const login = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: 'egomath.com',
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
      },
    });
  };


  async function signInWithDiscord() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'discord',
      options: {
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
      },
    })
  }  
  
  // Function to handle logout
  const logout = async () => {
    await supabase.auth.signOut();
    setSession(null); // Set session to null on logout
  };
  
  
  useEffect(() => {
    let authListener;
  
    // Function to check session on component mount
    const checkSession = async () => {
      const { data: initialSession } = await supabase.auth.getSession();
      // Set session to the user's session or null if not signed in
      setSession(initialSession?.session ?? null);
      console.log(initialSession);
  
      // Listen to auth state changes
      const { data: listener } = supabase.auth.onAuthStateChange((event, session) => {
        switch (event) {
          case "SIGNED_IN":
            setSession(session?.user); // Set session to the signed-in user
            break;
          case "SIGNED_OUT":
            setSession(null); // Set session to null on sign-out
            break;
          default:
        }
      });
    };
  
    checkSession();
  
    // Clean up the auth listener on component unmount
    return () => {
      if (authListener) {
        authListener.data.unsubscribe();
      }
    };
  }, []);

  const [showSignInMessage, setShowSignInMessage] = useState(false);

  const checkSignInStatus = () => {
    if (!session && consecutiveCorrect === 8) {
      setShowSignInMessage(true);
    } else {
      setShowSignInMessage(false);
    }
  };

  const onSubmit = (consecutiveCorrect) => {
    // Handle the consecutiveCorrect value as needed
    console.log("Consecutive Correct:", consecutiveCorrect);

    if (!session && consecutiveCorrect === 8) {
      setShowSignInMessage(true);
    } else {
      setShowSignInMessage(false);
    }
    // Additional logic if necessary
  };


  useEffect(() => {
    checkSignInStatus();
  }, [session, currentDifficulty, setConsecutiveCorrect]);

  

  const difficultyInfo = {
    Padawan: "1 calculation per problem",
    Apprentice: "2 calculations per problem",
    Jedi: "3+ calculations per problem",
  };




    // Loop through difficulties to create tooltips for each button
    Object.keys(difficulties).forEach((difficulty) => {
      const buttonSelector = `.difficulty-selector button[data-difficulty="${difficulty}"]`;
      tippy(buttonSelector, {
        content: difficultyInfo[difficulty], // Set the content based on difficulty
        delay: 200,
        
   
      });
    });

    useEffect(() => {
      tippy('.wrapper-counter', {
        content: "Number of solved problems",
        delay: 200,
      });
    }, []);


  useEffect(() => {
    tippy('.prev-btn', {
      content: "Previous",
      delay: 200,
    });
  
    tippy('.next-btn', {
      content: "Next",
      delay: 200,
    });
  }, [currentProblem]);

  

  return (
    <div className="app">
      <div className="header">
        <div className="left-content">
          <a href="/">
          <div id="logo"></div>
          <h2>egoMath</h2>
          </a>
        </div>

        <div className="right-content">
          <div className="wrapper-counter">
        <div className="counter">
          <img id="brain" src={brain}></img><p>{consecutiveCorrect}</p>
        </div>
        </div>
          {session ? (
            
            <div className="header-buttons">
              <button className="btn-2" onClick={logout}>Logout</button>
            </div>
            
          ) : (
            <button className="btn-1" onClick={login}> <img src={googlelogo} alt="G" id="g-logo" />Continue with Google</button>
          )}
        </div>
        
      </div>

      <div className="center-content">
        
          <div className="difficulty-selector">
            {Object.keys(difficulties).map((difficulty) => (
              <button
                key={difficulty}
                className={difficulties[difficulty].selected ? 'selected' : ''}
                onClick={() => handleDifficultyChange(difficulty)}
                data-difficulty={difficulty}
              >
                {difficulty}
              </button>
            ))}
          </div>
        </div>
          
      

      {showSignInMessage ? (
        <div className="content-body">
          <div className="sign-in-message">
            <h1>Login or create a free account to continue</h1>
            <div className="ctas">
            <button className="btn-prim" onClick={login}><img src={googlelogo} alt="G" id="g-logo" />Continue with Google</button>
            <button className="btn-1-full" onClick={signInWithDiscord}><img src={discordlogo} alt="G" id="d-logo" />Continue with Discord</button>
            </div>
          </div>
        </div>
      ) : currentProblem ? (
        <>
        <div className="content-body">
          <div className="controls">
            <button className="prev-btn" onClick={getPreviousProblem}>
              <IconContext.Provider value={{ className: "icon" }}>
                <FaArrowLeft />
              </IconContext.Provider>
            </button>
            <h3 id="instructions">{difficultyInstructions[currentDifficulty]}</h3>
            <button className="next-btn" onClick={getNextProblem}>
              <IconContext.Provider value={{ className: "icon" }}>
                <FaArrowRight />
              </IconContext.Provider>
            </button>
          </div>
          <div className="problem">
            <ProblemComponent question={currentProblem.question} />
          </div>
          <AnswerForm 
            correctAnswer={currentProblem.answer}
            onSubmit={() => getNextProblemSubmit()}
            onAnswer={handleAnswer} // Pass the new onAnswer prop
            difficulty={currentDifficulty}
            consecutiveCorrect={consecutiveCorrect}
          />
          </div>
        </>
      ) : (
        <p>No more problems!</p>
      )}
      {showPopup && <Popup />}
    </div>
  );
};

export default App;